'use strict';

//sentry logging
Raven.config('https://b66b9463820748dbaf2a0c3c11a39487@sentry.io/133551', {
    autoBreadcrumbs: true
}).install()


toastr.options = {
    "progressBar": true,
    "closeButton": true
};

$(document).ready(function () {
    $('.search-trigger-holder').on('click', function () {
        $(this).toggleClass('active');
        $('#search-wrapper').slideToggle(function () {
            if ($('#search-wrapper').css('display') == 'none') {
                stickFooterToBottom();
            }
        });

    });
});


var customPlayer;
$(document).ready(function () {
    $.each($('.video-player'), function (key, value) {
        var uploaderId = $(this).data().uploaderId;
        var uploaderName = $(this).data().uploaderName;
        var uploadedDate = $(this).data().uploadedDate;
        var userHtml = '';

        if (uploaderId != '' && uploaderName != '') {  //кофти проверка @todo: да се измисли нещо по-добро защото с тея стрингове не е добре (един интервал в повече и всичко приключва)
            userHtml = '<div class="pull-left mt5 label label-default"> Добавен от <a href="/users/' + uploaderId + '/my-songs">' + uploaderName + '</a> на ' + uploadedDate + '</div>';
        } else {
            userHtml = ''; //за всеки случай
        }


        if ($(this).data().source == "youtube") {
            $(this).html('<div data-type="youtube" data-video-id="' + $(this).data().id + '"></div><div class="clearfix"></div>');
            customPlayer = plyr.setup();
            customPlayer.forEach(function () {
                $(this)
                    .on('ended', function (event) {
                        $('.video-suggestions').fadeIn();
                    })
                    .on('play', function () {
                        $('.video-suggestions').fadeOut();
                    });
                $('#repeat-btn').show();
            });
            $('#repeat-btn').after(userHtml);

            $('*[data-source="vbox"]').remove();
            return false;
        } else if ($(this).data().source == "vbox") {
            $(this).html('<iframe width="660" height="315" src="https://www.vbox7.com/emb/external.php?vid=' + $(this).data().id + '" frameborder="0" allowfullscreen></iframe><div class="clearfix"></div>');
            $(this).after(userHtml);
            $('*[data-source="youtube"]').remove();
            return false;
        } else {
            console.log('unknown source');
        }
    });

});


function stickFooterToBottom() {
    var bodyHeight = $('body').height() + 100;
    var vwptHeight = $(window).height();
    if (vwptHeight > bodyHeight) {
        $("footer").css({
            'position': 'absolute',
            'left': '0',
            'bottom': '0'
        });
    } else {
        $('footer').css('position', 'static');
    }
}
$(document).ready(function () {
    stickFooterToBottom();
});

$(window).on('resize', function () {
    stickFooterToBottom();
});

/*
 // Function called if AdBlock is not detected
 function adBlockNotDetected() {
 // $('#addblock-msg').show();
 }
 // Function called if AdBlock is detected
 function adBlockDetected() {
 $('#addblock-msg').css('display', 'block');
 $('body').css('overflow', 'hidden');
 }

 // Recommended audit because AdBlock lock the file 'fuckadblock.js'
 // If the file is not called, the variable does not exist 'fuckAdBlock'
 // This means that AdBlock is present
 if (typeof fuckAdBlock === 'undefined') {
 adBlockDetected();
 } else {
 fuckAdBlock.onDetected(adBlockDetected);
 fuckAdBlock.onNotDetected(adBlockNotDetected);
 // and|or
 fuckAdBlock.on(true, adBlockDetected);
 fuckAdBlock.on(false, adBlockNotDetected);
 // and|or
 fuckAdBlock.on(true, adBlockDetected).onNotDetected(adBlockNotDetected);
 }

 // Change the options
 fuckAdBlock.setOption('checkOnLoad', false);
 // and|or
 fuckAdBlock.setOption({
 debug: false,
 checkOnLoad: true,
 resetOnEnd: false
 });
 */

$(document).ready(function () {
    $('.filter-wrapper input:checkbox').on('change', function () {
        $(this).closest('form').submit();
    });
    $('#genre-box,#lang-box').simplebar({
        autoHide: true,
    });
    if ($('.mobile-filter-trigger').length > 0) {
        $('.mobile-filter-trigger').on('click', function () {
            $('.filter-wrapper').toggleClass('open');
        });
        $(window).scroll(function () {
            $('.filter-wrapper').removeClass('open');
        });
    }

});


$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});


//login form
$(function () {
    $('.button-checkbox').each(function () {
        var $widget = $(this),
            $button = $widget.find('button'),
            $checkbox = $widget.find('input:checkbox'),
            color = $button.data('color'),
            settings = {
                on: {
                    icon: 'glyphicon glyphicon-check'
                },
                off: {
                    icon: 'glyphicon glyphicon-unchecked'
                }
            };

        $button.on('click', function () {
            $checkbox.prop('checked', !$checkbox.is(':checked'));
            $checkbox.triggerHandler('change');
            updateDisplay();
        });

        $checkbox.on('change', function () {
            updateDisplay();
        });

        function updateDisplay() {
            var isChecked = $checkbox.is(':checked');
            // Set the button's state
            $button.data('state', (isChecked) ? "on" : "off");

            // Set the button's icon
            $button.find('.state-icon')
                .removeClass()
                .addClass('state-icon ' + settings[$button.data('state')].icon);

            // Update the button's color
            if (isChecked) {
                $button
                    .removeClass('btn-default')
                    .addClass('btn-' + color + ' active');
            }
            else {
                $button
                    .removeClass('btn-' + color + ' active')
                    .addClass('btn-default');
            }
        }

        function init() {
            updateDisplay();
            // Inject the icon if applicable
            if ($button.find('.state-icon').length == 0) {
                $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
            }
        }

        init();
    });
});
$(".validate").each(function () {
    $(this).validate({
            ignore: "",
            rules: {
                field: {
                    required: true,
                    email: true
                },
                password: "required",
                password_confirmation: {
                    equalTo: "#password"
                }
            },
            invalidHandler: function (event, validator) {
                stickFooterToBottom();
            }
        }
    );
});

//login form


$("#singer-exist").select2({
    minimumInputLength: 1,
    multiple: true,
    ajax: {
        type: "POST",
        url: '/songs/ajaxArtist',
        dataType: 'json',
        contentType: "application/json",
        delay: 250,
        data: function (params) {
            return JSON.stringify({
                query: params.term
            });
        },
        processResults: function (data) {
            // console.log(data.suggestions);
            return {
                results: $.map(data.suggestions, function (item, i) {
                    return {
                        text: item.value,
                        id: item.data
                    }
                })
            };
        }
    }
});
$('#category, #lang').select2();
autosize($('textarea'));

var index = 0;
$('.add-new-artist-trigger').on('click', function () {
    $('#new-artist-wrapper').append('<div class="form-group">' +
        '<label for="singer-new-' + index + '">Добавете нов</label><span class="pull-right remove-new-singer btn btn-link"><i class="fa fa-minus" aria-hidden="true"></i></span>' +
        '<input type="text" name="additional_artist[]" class="form-control" id="singer-new-' + index + '" />' +
        '</div>');
    index++;
});

$('body').on('click', '.remove-new-singer', function () {
    $(this).closest('.form-group').remove();
});

$('.remove-new-singer').on('click', function () {
    $(this).closest('.form-group').remove();
});


//fb login
$('body').on('click', '.social-btn-fb', function () {
    fbLogin($(this).data());
});

function fbLogin(data) {
    // console.log('Prepare fb login...');
    FB.login(function (response) {
        if (response.authResponse) {
            // console.log('Welcome! Fetching your information.... ');
            FB.api('/me', function (response) {
                window.location = data.href;
            });
        } else {
            console.warn('FB login fail');
        }
    }, {
        scope: data.scope
    });
}

$('.add-text-validate').validate({
        errorPlacement: function (error, element) {
            if (element.hasClass("select2")) {
                error.insertAfter(element.next());
            } else {
                error.insertAfter(element);
            }
        },
        ignore: "",
        rules: {
            field: {
                required: true
            }
        },
        invalidHandler: function (event, validator) {
            stickFooterToBottom();
        }
    }
);

$('.translate-validate').validate();

$(document).ready(function () {
    $('.translate-trigger').on('click', function () {
        var self = $(this);
        $('#translate-holder').slideDown('slow', function () {
            var textarea = $(this);
            self.hide();
            $('.translate-sent').show();
            $('#remove-js').slideUp(400, function () {
                $(this).remove();

            });
            textarea.css('padding-top', 0);
        });
    });
});


//report bug

function refreshCaptcha() {
    $.ajax({
        url: "/index/refereshcapcha",
        type: 'get',
        dataType: 'html',
        success: function (json) {
            $('.refereshrecapcha').html(json);
        },
        error: function (data) {
            alert('Try Again.');
        }
    });
}

/*
 (function ($) {
 $.fn.feedback = function (success, fail) {

 var self = $(this);
 self.find('.dropdown-menu-form').on('click', function (e) {
 e.stopPropagation()
 });


 self.find('.do-close').on('click', function () {
 //self.find('.dropdown-toggle').dropdown('toggle');
 self.find('.dropup').removeClass('open');
 self.find('.reported, .failed').hide();
 self.find('.report').show();
 self.find('textarea').val('');
 });

 function failed() {
 self.find('.failed').show();
 if (fail) fail();
 };

 self.find('.btn-sent-report').on('click', function (e) {
 e.preventDefault();
 if ($(this).closest(".report-validate").valid()) {
 self.find('form').submit();
 }
 });

 self.find('form').on('submit', function () {

 $.post($(this).attr('action'), $(this).serialize(), null, 'json').done(function (res) {
 if (res.status == true) {
 self.find('.report').hide();
 self.find('.reported').show();
 } else {
 if (res.data.currentRule == 'captcha') {
 toastr.error('Грешен код.');
 }
 }
 }).fail(function () {
 failed();
 });
 refreshCaptcha();
 return false;
 });
 };
 }(jQuery));
 */
$(document).ready(function () {
    $('.feedback').each(function () {
        $(this).feedback();
    });
});

if (navigator.userAgent.match(/Trident\/7\./)) { // if IE
    $('header.bg ,.index-bg').css('background-attachment', 'scroll');
}

$(document).ready(function () {
    $('.btn-add-to-favorites').on('click', function () {
        var $self = $(this);
        var songId = $self.data().songId;
        $.ajax({
            method: "POST",
            url: "/songs/addFavorite",
            data: {
                song_id: songId
            }
        })
            .done(function (response) {
                if (response.method == "add") {
                    $self.addClass('active').find('.fa').removeClass('fa-heart-o').addClass('fa-heart');
                    $self.find('.text').text('Добавена в любими');
                    toastr.success(response.data);
                } else {
                    $self.removeClass('active').find('.fa').removeClass('fa-heart').addClass('fa-heart-o');
                    $self.find('.text').text('Добави в любими');
                    toastr.success(response.data);
                }
            }).fail(function () {
            console.warn('Ajax error');
        });
    });

});

//fade different translates
$(document).ready(function () {
    var preventDoubleClick = false;
    $('.trigger-lang').stop(true, true).on('click', function (event) {
        event.stopPropagation();
        if (preventDoubleClick) {
            return false;
        }
        $('.trigger-lang.opacity').removeClass('opacity');
        var indexShow = $(this).data().index;
        preventDoubleClick = true;
        $('.lang-holder:visible').stop(true, true).fadeOut(300, function () {
            $('.index' + indexShow).stop(true, true).fadeIn(300, function () {
                $(".trigger-lang:not([data-index='" + indexShow + "'])").addClass('opacity');
                preventDoubleClick = false;
            });
        });
    });
});

$(document).ready(function () {
    $('.remove-from-favorite').confirm({
        confirmButton: 'Потвърди',
        cancelButton: 'Отказ',
        animation: 'scaleX',
        closeAnimation: 'scaleY',
        title: 'Премахване!',
        content: 'След потвърждаване песента ще бъде премахната от списъка Ви с любими песни',
        confirm: function () {
            var $self = this.$target;
            var songId = $self.data().songId;
            $.ajax({
                method: "POST",
                url: "/songs/addFavorite",
                data: {
                    song_id: songId
                }
            })
                .done(function (response) {
                    toastr.info(response.data);
                    window.location.reload();
                }).fail(function () {
                console.warn('Ajax error');
            });
        }
    });
});

$(document).ready(function () {
    $('#want-translate-trigger').on('click', function () {
        var $self = $(this);
        var songId = $(this).data().songId;


        if ($self.hasClass('not-wanted')) {
            var confirm = $.confirm({
                title: 'Моля, изберете език',
                content: 'На кой език желаете да е превода?<br> <select class="form-control" id="ajax-languages"></select>',
                confirmButton: 'Готово',
                cancelButton: 'Затвори',
                confirmButtonClass: 'btn-info',
                confirm: function () {
                    var selectedLangId = $('#ajax-languages>option:selected').attr('value');
                    $.ajax({                            //@todo: make function to prevent double code
                        url: "/songs/wantTranslate",
                        type: 'post',
                        dataType: 'json',
                        data: {
                            song_id: songId,
                            language_id: selectedLangId
                        },
                        success: function (response) {
                            toastr.success(response.data);
                            $('#updatable').show().find('>span').html(response.message);
                            if (response.method == "add") {
                                $self.removeClass('not-wanted').text('Вече не искам превод');
                            } else {
                                $self.addClass('not-wanted').text('Искам превод');
                            }
                        },
                        error: function (data) {
                            console.warn('ajax fail.');
                        }
                    });
                }
            });

            $.ajax({
                url: '/songs/ajaxLanguages',
                method: 'POST'
            }).done(function (response) {
                $(response).each(function () {
                    $('#ajax-languages').append('<option value="' + $(this)[0].id + '">' + $(this)[0].name + '</option>');
                });

            });

        } else {
            if (confirm) {
                confirm.close();
            }
            $.ajax({                             //@todo: make function to prevent double code
                url: "/songs/wantTranslate",
                type: 'post',
                dataType: 'json',
                data: {
                    song_id: songId
                },
                success: function (response) {
                    toastr.success(response.data);
                    $('#updatable').show().find('>span').html(response.message);
                    if (response.method == "add") {
                        $self.removeClass('not-wanted').text('Вече не искам превод');
                    } else {
                        $self.addClass('not-wanted').text('Искам превод');
                    }
                },
                error: function (data) {
                    console.warn('ajax fail.');
                }
            });
        }

    });
});

$('.report-dropdown-wrapper').on('show.bs.dropdown', function () {
    $(this).find('.refereshrecapcha').html('<img src="/captcha/default?{{time()}}" alt="captcha"/>');
});


/* player repeat*/
$('#repeat-btn').on('click', function () {
    $(this).find('.fa').toggleClass('fa-spin');
    $(this).toggleClass('repeat-on');
});
$(document).ready(function () {
    if (customPlayer) {
        customPlayer[0].on('ended', function () {
            if ($('#repeat-btn').hasClass('repeat-on')) {
                customPlayer[0].play();
            }
        });
    }
});


//new report(with bootstrap modal)
$('.report-modal-trigger').on('click', function () {

    var placeholderText = $(this).data().text;
    var reportType = $(this).data().type;
    var route = $(this).data().route;
    var requestUrl = $(this).data().requestUrl;
    var currentDate = new Date();
    var token = $(this).data().token;

    console.log(token);


    var modal = '<div class="modal fade" id="report-modal" role="dialog" aria-labelledby="label">' +
        '<div class="modal-dialog" role="document">' +
        '<form method="post" class="modal-content report-validate relative"action="' + route + '">' +
        '<div id="loadingDiv"></div>' +
        '<div class="modal-header">' +
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
        '<h4 class="modal-title" id="label">Докладвай за нередност</h4>' +
        '</div>' +
        '<div class="modal-body ">' +
        '<div class="form-group">' +
        '<label for="reportarea">Съобщение</label>' +
        '<textarea class="form-control" rows="3" id="reportarea" placeholder="' + placeholderText + '" name="comment"></textarea>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-sm-3">' +
        '<div class="refereshrecapcha"><img src="/captcha/default?' + currentDate + '" alt="captcha"></div>' +
        '</div>' +
        '<div class="col-sm-3">' +
        '<input class="form-control" id="captcha"" placeholder="Код" name="captcha" type="text" value="">' +
        '</div>' +
        '<div class="col-sm-3">' +
        '<button type="button" class="btn btn-default report-modal-refresh-captcha"><i class="fa fa-refresh" aria-hidden="true"></i></button>' +
        '</div>' +
        '</div>' +
        '<input name="type" type="hidden" value="' + reportType + '">' +
        '<input name="url" type="hidden" value="' + requestUrl + '">' +
        '<input name="_token" type="hidden" value="' + token + '">' +
        '</div>' +
        '<div class="modal-footer">' +
        '<button type="button" class="btn btn-default" data-dismiss="modal">Отказ</button>' +
        '<button type="url" class="btn btn-primary" id="send-report-modal">Изпрати</button>' +
        '</div>' +
        '</form>' +
        '</div>' +
        '</div>';

    $('footer').before(modal);

});

$(document.body).on('click', '#send-report-modal', function (e) {
    e.preventDefault();
    var self = $(this);
    var isFormValid = self.closest('form').find('#reportarea').val().length > 0 && self.closest('form').find('#captcha').val().length > 0;
    if (!isFormValid) {
        toastr.error('Моля, попълнете полетата за съобщение и код за сигурност');
        return false;
    }
    var loader = self.closest('form').find('#loadingDiv');
    loader.css('display', 'block');
    var formData = self.closest('.report-validate').serialize();
    var action = self.closest('.report-validate').attr('action');
    $.post(action, formData, null, 'json')
        .done(function (response) {
            loader.css('display', 'none');
            if (response.status == true) {
                toastr.success(response.data);
                $('#report-modal').modal('hide');
            } else {
                if (response.data.currentRule == 'captcha') {
                    toastr.error('Грешен код.');
                    self.closest('form').find('.refereshrecapcha img').attr('src', '/captcha/default?' + new Date());
                }
            }

        }).fail(function (response) {
        loader.css('display', 'none');
        console.warn(response);
    });
});

$(document.body).on('click', '.report-modal-refresh-captcha', function (e) {
    e.preventDefault();
    var currentDate = new Date();
    $(this).closest('.row').find('.refereshrecapcha img').attr('src', '/captcha/default?' + currentDate);
});


$(document.body).on('hidden.bs.modal', '#report-modal', function () {
    $(this).data('bs.modal', null);
    $('#report-modal').remove();
});

